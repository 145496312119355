/// That file is a client-side file that will be loaded in the browser. It will be used to initialize the assistant and listen to the messages from the assistant.
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { authStore, routerStore } from './stores'
import { useInterval } from 'react-use'
import { useDebounceEffect } from 'ahooks'
import qs from 'query-string'

// Copy the action types from the assistant server-side file
const ACTION_TYPES = {
	ONBOARDING: 'onboarding',
	SEARCH_JOBS: 'search_jobs',
	TIMECARD: 'timecard',
	REGISTER_ACCOUNT: 'register_account',
	COMPLETE_PROFILE: 'complete_profile',
	CREATE_ACCOUNT_SUCCESSFULLY: 'create_account_successfully',
	CHECK_NEW_JOB_OPENINGS_AND_MATCHES: 'check_new_job_openings_and_matches',
	CHECK_ENDING_UPCOMING_ASSIGNMENTS: 'check_ending_upcoming_assignments',
	GET_CLINICIAN_DETAIL: 'get_clinician_detail',
	CHECK_EXPIRED_DOCUMENT_OR_CREDENTIALS: 'check_expired_document_or_credentials',
	REVIEW_AND_SUBMIT_JOB_SUBMISSIONS: 'review_and_submit_job_submissions',
	CHECK_NEWLY_ASSIGNED_CLINICIANS: 'check_newly_assigned_clinicians',
	IDENTIFY_POTENTIAL_CHURN_CLINICIANS: 'identify_potential_churn_clinicians',
	GET_JOB_DETAIL: 'get_job_detail',
	UNIDENTIFIED: 'unidentified',
}

// Override the navigateTo function
const navigateTo = (action) => {
	const { action_params } = action
	const redirect_url = action_params?.redirect_url

	if (redirect_url) {
		routerStore.push(redirect_url)
		return
	}

	const queryString = qs.stringify({ ...action_params, action, from: 'ai' })

	switch (action.action_type) {
		case ACTION_TYPES.SEARCH_JOBS:
			routerStore.push(`/recruiter_jobs?${queryString}`)
			break
		case ACTION_TYPES.TIMECARD:
			routerStore.push(`/home?${queryString}`)
			break
		case ACTION_TYPES.ONBOARDING:
			routerStore.push(`/home?${queryString}`)
			break
		case ACTION_TYPES.CREATE_ACCOUNT_SUCCESSFULLY:
			routerStore.push(`/user_profile?${queryString}`)
			break
		case ACTION_TYPES.CHECK_NEW_JOB_OPENINGS_AND_MATCHES:
			routerStore.push(`/home/matches?${queryString}`)
			break
		case ACTION_TYPES.CHECK_ENDING_UPCOMING_ASSIGNMENTS:
			routerStore.push(`/home/recruiter_jobs?${queryString}`)
			break
		case ACTION_TYPES.GET_CLINICIAN_DETAIL:
			const clinicianId = action_params?.clinicianId
			if (clinicianId) routerStore.push(clinicianId ? `/candidate_info/${clinicianId}` : '/candidate_info')
			break
		case ACTION_TYPES.CHECK_EXPIRED_DOCUMENT_OR_CREDENTIALS:
			break
		case ACTION_TYPES.REVIEW_AND_SUBMIT_JOB_SUBMISSIONS:
			break
		case ACTION_TYPES.CHECK_NEWLY_ASSIGNED_CLINICIANS:
			break
		case ACTION_TYPES.IDENTIFY_POTENTIAL_CHURN_CLINICIANS:
			break
		case ACTION_TYPES.GET_JOB_DETAIL:
			routerStore.push(action_params?.jobId ? `/job_info/${action_params?.jobId}` : '/recruiter_jobs?' + queryString)
			break
		case 'unidentified':
			break
		default:
			break
	}
}

export const Assistant = observer(() => {
	/**
	 * Get the token from the auth store on client-side
	 * That means the client must be authenticated to use the assistant
	 * and use `mobx` to manage the state
	 * and must be defined the `authStore` in the `stores` file
	 */
	const { token, expiresAt } = authStore

	const [instance, setInstance] = useState(window.OpusAssistant)

	/**
	 * If you want to handle the messages from the assistant, you can do it here
	 */
	const onReceiveMessage = useCallback((data) => {
		console.debug('Received message from assistant:', data)
	}, [])

	/**
	 *  If you want to override the navigation, you can do it here
	 */
	const onReceiveAction = (data) => {
		navigateTo(data) // Default navigation
	}

	useInterval(
		() => {
			if (window.OpusAssistant) {
				setInstance(window.OpusAssistant)
			}
		},
		instance ? null : 1000
	)

	useDebounceEffect(
		() => {
			if (instance) {
				instance.init({
					baseUrl: 'https://opus-ai-assistant-maestro-qa.opusasia.io',
					token,
					expiresAt,
					onReceiveMessage,
					routerStore,
					onReceiveAction,
				})
			}
		},
		[instance, token, onReceiveMessage, onReceiveAction, expiresAt],
		{
			wait: 1000,
			leading: false,
			trailing: true,
		}
	)

	return null
})
