import axios from 'axios'
import { isEmpty } from 'lodash'
import { MASTER_DATA_FIELDS } from '~/common/constants'
import { action, observable, persist, store } from '~/common/mobx.decorator'
import { apolloClient, LIKE_DISLIKE_JOB_MUTATION } from '~/common/apollo'
import { notifyStore } from '~/stores'

@store()
class MasterStore {
	@observable disciplines
	@observable locations
	@observable referrals
	@observable agencies
	@observable chartings
	@observable primarySpecialities
	@observable secondarySpecialities
	@persist @observable licenseStates
	@observable referenceRelationships
	@observable jobDisciplines
	@observable jobShifts
	@observable profession
	@observable originalSpecialties = {}
	@observable specialties = []
	@observable reasons = []
	@observable locations = []
	@observable salutations = []
	@observable relationships = []
	@observable documentTypes = []
	@observable facilityTypes = []
	@observable jobSettings = []
	@observable referredRelationships = []
	@observable workLocations = []
	@observable originalLocations = []
	@observable assignmentTypes = []
	@observable cityStates = []
	@observable jobApplicantStage = []
	@observable workerAssignmentStage = []
	@observable activityTypes = []
	@observable recruiterRoles = []
	@observable recruiterFullRoles = []
	@observable states = []
	@observable retentionStatues = []
	@persist @observable specialtyOptions = []
	@persist @observable listSpecialtyName = []
	@persist @observable jobTypes
	@persist @observable masterData = {}
	@persist @observable frequencyMasterData = {}
	@persist @observable alertChannelMasterData = {}
	@persist @observable pauseAlertPeriodMasterData = {}

	@action
	fetchStates = async (force = false) => {
		if (!force && !isEmpty(this.states)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.states,
			},
		})
		this.states = response.data?.map((item) => ({ value: item.name, label: item.name }))
	}

	@action
	fetchRecruiterFullRoles = async (force = false) => {
		if (!force && !isEmpty(this.recruiterFullRoles)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.recruiterFullRoles,
			},
		})
		this.recruiterFullRoles = response.data?.map((item) => ({ value: item.id, label: item.name }))
	}

	@action
	fetchRecruiterRoles = async (force = false) => {
		if (!force && !isEmpty(this.recruiterRoles)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.recruiterRoles,
			},
		})
		this.recruiterRoles = response.data?.map((item) => ({ value: item.id, label: item.name }))
	}

	@action
	fetchJobStage = async (force = false) => {
		if (!force && !isEmpty(this.jobApplicantStage)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.jobApplicantStage,
			},
		})
		this.jobApplicantStage = response.data
	}

	@action
	fetchRetentionStatues = async (force = false) => {
		if (!force && !isEmpty(this.retentionStatues)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.retentionStatues,
			},
		})
		this.retentionStatues = response.data?.map((item) => ({ value: item.id, label: item.name }))
	}

	@action
	fetchWorkerAssignmentStage = async (force = false) => {
		if (!force && !isEmpty(this.workerAssignmentStage)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.workerAssignmentStage,
			},
		})
		this.workerAssignmentStage = response.data
	}

	@action
	fetchTypeJobs = async (force = false) => {
		if (!force && !isEmpty(this.jobTypes)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.employmentTypes,
			},
		})
		this.jobTypes = response.data?.map((item) => ({
			label: item.name,
			value: item.name,
		}))
	}

	@action
	fetchJobShifts = async (force = false) => {
		if (!force && !isEmpty(this.jobShifts)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: 'shift',
			},
		})
		this.jobShifts = response.data?.map((item) => ({ label: item.name, value: item.id }))
	}

	@action
	fetchLocations = async (force = false) => {
		if (!force && !isEmpty(this.locations)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.preferredLocations,
			},
		})

		this.locations = response.data
	}

	@action
	fetchReferenceRelationships = async (force = false) => {
		if (!force && !isEmpty(this.referenceRelationships)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referenceRelationships,
			},
		})

		this.referenceRelationships = response?.data || []
	}

	@action
	fetchTravelerRelationship = async (force = false) => {
		if (!force && !isEmpty(this.referredRelationships)) {
			return
		}
		const response = await axios.get('/company_configs/master_data')

		const relationships = response?.data?.referred_traveler?.relationship
		let data = Object.keys(relationships).map((key) => ({
			label: relationships[key],
			value: key,
		}))

		this.referredRelationships = data
	}

	@action
	fetchReferrals = async (force = false) => {
		if (!force && !isEmpty(this.referrals)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referral,
			},
		})

		this.referrals = response.data
	}

	@action
	fetchAgencies = async (force = false) => {
		if (!force && !isEmpty(this.agencies)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.agency,
			},
		})

		this.agencies = response.data
	}

	@action
	fetchChartings = async (force = false) => {
		if (!force && !isEmpty(this.chartings)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.charting,
			},
		})

		this.chartings = response.data
	}

	@action
	fetchSecondarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.secondarySpecialities)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.secondarySpecialty,
			},
		})

		this.secondarySpecialities = response.data
	}

	@action
	fetchLicenseStates = async (force = false) => {
		if (!force && !isEmpty(this.licenseStates)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.licenseState,
			},
		})
		this.licenseStates = Object.entries(response.data).map(([key, value]) => ({ label: value, value: key }))
	}

	@action
	fetchPrimarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.primarySpecialities)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.specialty,
			},
		})

		this.primarySpecialities = response.data?.map((item) => ({ value: item.id, label: item.name }))
	}

	@action
	onFavoriteJob = async (values) => {
		const { jobId, action } = values?.variables
		try {
			const response = await apolloClient().mutate({
				mutation: LIKE_DISLIKE_JOB_MUTATION,
				variables: {
					jobId: jobId,
					action: action,
				},
			})
			notifyStore.success('$MESSAGES.SUCCESSFUL')
			return response?.data?.likeOrDislikeAJob
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchRelationships = async (force = false) => {
		if (!force && !isEmpty(this.relationships)) {
			return
		}

		const response = await axios.get('/company_configs/master_data')
		this.relationships = response?.data?.worker_reference?.relationship
	}

	@action
	fetchSalutations = async (force = false) => {
		if (!force && !isEmpty(this.salutations)) {
			return
		}

		const response = await axios.get('/company_configs/master_data')
		this.salutations = response?.data?.worker_reference?.salutation
	}

	@action
	fetchReasonLeavings = async (force = false) => {
		if (!force && !isEmpty(this.reasons)) {
			return
		}
		const response = await axios.get('/company_configs/master_data')
		this.reasons = response?.data?.work_experience?.reason_for_leaving
	}

	@action
	fetchAssignmentTypes = async (force = false) => {
		if (!force && !isEmpty(this.assignmentTypes)) {
			return
		}
		const response = await axios.get('/company_configs/master_data')
		this.assignmentTypes = response?.data?.work_experience?.assignment_type
	}

	@action
	fetchSupportDocumentTypes = async (force = false) => {
		if (!force && !isEmpty(this.documentTypes)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.documentType,
			},
		})

		this.documentTypes = response.data
	}

	@action
	fetchSpecialties = async (force = false) => {
		if (!force && !isEmpty(this.specialtyOptions)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.discipline,
			},
		})

		const disciplines = response?.data?.map((item) => ({
			label: item?.name || '',
			value: item?.id || '',
			specialties: item?.specialties?.map((item) => ({ label: item?.name || '', value: item?.id || '' })),
			groupLabel: 'Discipline',
		}))

		const specialties = response?.data?.reduce((accumulative, currentItem) => {
			const dependentValues = currentItem?.specialties?.map((item) => ({
				label: item?.name || '',
				value: item?.id || '',
				disciplineId: currentItem?.id || '',
				groupLabel: currentItem?.name || '',
			}))
			return [...accumulative, ...dependentValues]
		}, [])

		this.specialtyOptions = [...specialties, ...disciplines]
		this.disciplines = disciplines

		const removeDuplicates = (options) => {
			const seen = new Map()
			return options.filter((option) => !seen.has(option.value) && seen.set(option.value, 1))
		}

		this.primarySpecialities = [...removeDuplicates(specialties)]
	}

	@action
	fetchFacilityTypes = async (_force = false, name) => {
		const response = await axios.get(`/facilities${name ? `?name=${name}` : ''}`)

		const facilities = response.data?.map((item) => ({
			label: item?.name,
			value: item?.id,
			groupLabel: 'Facility',
		}))

		const facilityTypes = response.data?.reduce((accumulative, currentItem) => {
			const dependentValues =
				currentItem?.facility_types?.map((item) => ({
					label: item,
					value: item,
					groupLabel: currentItem.name,
				})) || []
			return [...accumulative, ...dependentValues]
		}, [])

		this.facilityTypes = [...facilityTypes, ...facilities]
	}

	@action
	fetchJobSettings = async (force = false) => {
		if (!force && !isEmpty(this.jobSettings)) {
			return
		}
		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.jobSettings,
			},
		})
		this.jobSettings = response.data.map((item) => ({
			label: item?.name,
			value: item?.id,
		}))
	}

	@action
	fetchActivityTypes = async (force = false) => {
		if (!force && !isEmpty(this.activityTypes)) {
			return
		}
		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.activityTypes,
			},
		})
		this.activityTypes = response.data
			.map((item) => ({
				label: item?.name,
				value: item?.id,
			}))
			?.filter((item) => item?.value !== 'score_activities')
	}

	@action
	fetchWorkLocations = async (force = false) => {
		if (!force && !isEmpty(this.workLocations)) {
			return
		}

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.state,
			},
		})

		const locations = response?.data

		this.originalLocations = response?.data
		let data = Object.keys(locations).map((key) => ({
			label: locations[key],
			value: key,
		}))

		this.workLocations = data
	}

	@action
	fetchCityState = async (force = false) => {
		if (!force && !isEmpty(this.cityStates)) {
			return
		}

		// const response = await axios.get(`/cities/search?terms=${input}`)

		const response = await axios.get('/picklists', {
			params: {
				picklist_type: MASTER_DATA_FIELDS.cities,
			},
		})

		const locations = response?.data

		this.originalLocations = response?.data
		let data = Object.keys(locations).map((key) => ({
			label: locations[key],
			value: key,
		}))

		this.cityStates = data
	}
}

export const masterStore = new MasterStore()
